import clevertap from "clevertap-web-sdk";



export default function useClevertap() {
  function handleEventPushClick(eventName: string, eventPayload: any) {
    clevertap.event.push(eventName, eventPayload); // Replace Payload as per your event schema and design
  }

  function onUserLogin(userObject: any) {
    clevertap.onUserLogin.push(userObject); // Replace with user profile object of the logged in user
  }

  return {
    handleEventPushClick,
    onUserLogin,
  };
}
