import Footer from './components/Footer';
import Header from './components/Header';
import useClevertap from './hooks/useClevertap';
import HomePage from './screens/Homepage';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import React, { useEffect } from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SharePetPlaylist from './screens/SharePetPlaylist';

function App() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  if (process.env.REACT_APP_DATABASE_URL) {
    firebaseConfig.databaseURL = process.env.REACT_APP_DATABASE_URL;
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app); // Assuming you're going to use Firestore here

  // Clevertap event push
  const { handleEventPushClick } = useClevertap();

  useEffect(() => {
    // Tracking page visit event
    handleEventPushClick('Page Visit', { 'PageName': window.location.pathname });
    handleEventPushClick('PET_PLAYLIST_PAGE_VISIT', { 'PageName': window.location.pathname });
  }, []); // Empty dependency array, event only triggers on mount

  return (
    <Router>     
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/share/:playlistLink/:petName" element={<SharePetPlaylist/>}/>
      </Routes>
    </Router>
  );
}

export default App;
