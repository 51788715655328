import { useEffect, useState } from 'react';
import axios from 'axios';
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider,
    User,
} from "firebase/auth";
import { generateUsername } from "friendly-username-generator"
type UserInfo = {
    email?: string; firstname: string;
  };
const useLogin = () => {

    const [accessToken, setAccessToken] = useState("");
    const [firebaseUser, setFirebaseUser] = useState({});
    const [uuid, setUuid] = useState("");
    const [username, setUsername] = useState("");
    const [userCreated, setUserCreated] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [userFirstName, setUserFirstName] = useState("")

    useEffect(() => {
        const auth = getAuth();

        const unsubscribe = auth.onAuthStateChanged((user) => {
            console.log("User!!!!!", user);
            if (user) {
                setFirebaseUser(user);
                user.getIdToken().then((token) => {
                    setAccessToken(token);
                });
            } else {
                setAccessToken("");
            }
        });
    
        return () => unsubscribe();
    }, []);

    const getUser = async () => {
        if ((accessToken && accessToken.length > 0)) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_ASSET_API}api/v1/user/profile`,{
                    headers: {
                        Authorization: accessToken,
                        "Content-Type": "application/json",
                    },
                });
                if (response && response.data) {
                    console.log("get user profile", response.data)
                    localStorage.setItem("loggedIn", "true");
                    setUuid(response.data.data.uuid)
                    setUsername(response.data.data.username)
                    setUserFirstName(response.data.data.firstname)
                    setUserInfo(response.data.data)
                    setUserCreated(true);
                }
            } catch (error: any) {
                console.log("Error in getUser", error);
                if (error && error.response &&  error.response.status === 401) {
                    console.log("Here!!!")
                    await createUser()
                }
            }
        } else {
            console.log("no access token");
        }
    };

    useEffect(() => {
        if (accessToken && accessToken.length > 0) {
            getUser();
        }
    }, [accessToken])

    const handleGoogleSignIn = async (): Promise<User | null> => {
        const auth = getAuth();

        const provider = new GoogleAuthProvider();

        if (!accessToken) {
            try {
                // console.log("auth obj", auth);
                const result = await signInWithPopup(auth, provider);
                console.log("result", result);
                if (result.user) {
                    console.log("Google login success!", result?.user?.uid)
                }
                return result.user; 
            } catch (error) {
                console.log("Google sign in error", error)
                return null;
            }
        }
        return null
    }

    const handleAppleSignIn = async () => {
        const auth = getAuth();

        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');

        if (!accessToken) {
            try {
                const result = await signInWithPopup(auth, provider)
                console.log("result", result);
                if (result.user) {
                    console.log("Apple login success!")
                }
            } catch (error) {
                console.log("Apple sign in error", error)
            }
        }
    }

    function googleLogout() {
        const auth = getAuth();

        // Sign out the currently authenticated user
        auth
            .signOut()
            .then(() => {
                setAccessToken("");
                // console.log("Successfully logged out");
                localStorage.setItem("loggedIn", "false");
            })
            .catch((error) => {
                console.error("Logout error:", error);
            });
    }


    const createUser = async () => {
        const firebaseUser = getAuth().currentUser;
        if (firebaseUser !== null) {
            const username = generateUsername()//generate random username
            console.log("username in create user", username)
            setUserFirstName(firebaseUser.displayName || "")
            if (username && username !== null) {
                axios.post(`${process.env.REACT_APP_ASSET_API}api/v1/user/profile`,
                    {
                        uuid: firebaseUser.uid,
                        email: firebaseUser.email ? firebaseUser.email : "inboxdev.b@gmail.com",
                        firstname: firebaseUser.displayName,
                        username: username,
                        status: "PP",
                        profilepic: firebaseUser.photoURL,
                        mobile:
                        firebaseUser.phoneNumber !== null
                        ? parseInt(firebaseUser.phoneNumber)
                        : null,
                    },
                    {
                        headers: {
                            Authorization: `${accessToken}`,
                            "Content-Type": "application/json",
                        },
                    }
                ).then((response) => {
                    setUsername(username)
                    setUserCreated(true);
                    console.log("New user created")
                    getUser()
                }).catch((error) => {
                    console.log("Error creating new user profile", error);
                });
            }
        }
    };

    return { 
        handleGoogleSignIn, googleLogout, accessToken, firebaseUser, userCreated, uuid, setAccessToken, 
        setUserCreated, userFirstName, userInfo, username, handleAppleSignIn
    }
}

export default useLogin
